import { YAxisPlotBandsLabelOptions } from 'highcharts';

// Buffer so there is a spacer between lanes
export const LANE_BUFFER = 10;

export const CAPSULE_TIME_LANE_BUFFER = 14;

// Use common screen resolution widths as thresholds for recomputing chartWidth.
export const CHART_THRESHOLDS = [400, 800, 1280, 1920, 3840, 7680];

export const DEFAULT_AXIS_LABEL_COLOR = '#869298';

export const DEFAULT_AXIS_LINE_COLOR = '#C0D0E0';

export const DEFAULT_ITEM_COLOR = '#3A3A3A';

// Base config for all lane labels
export const LANE_LABEL_CONFIG: YAxisPlotBandsLabelOptions = {
  useHTML: true,
  align: 'right',
  verticalAlign: 'top',
  x: 0,
  y: 11,
  style: { color: DEFAULT_AXIS_LABEL_COLOR, whiteSpace: 'normal' },
};

// Buffer so that capsule time start/endOnTick labels don't overlap when multiple lanes display
export const PLOT_BAND = 'plotBand';

export const PLOT_BAND_AXIS_ID = `yAxis-${PLOT_BAND}`;

export const Z_INDEX = {
  SELECTED_REGION: 1,
  CAPSULE_REGION: 2,
  COVER_XAXIS_OVERFLOW: 3,
  CAPSULE_ICONS: 5,
  SELECTED_REGION_REMOVE: 10,
  CAPSULE_REGION_REMOVE: 11,
  LINE_BREAKS: 100,
  Y_AXIS_DEFINITION: 101,
};

export const ANALYSIS_COLORS = {
  // $sq-dark-primary-analysis
  DARK_PRIMARY: '#007960',
  // $sq-light-color-analysis
  LIGHT_COLOR: '#EDF7F6',
};

export const CURSOR_TOOLTIP_HEIGHT = 18;

export const LISTEN_ITEM_PROPERTIES = [
  'selected',
  'yAxisConfig',
  'axisVisibility',
  'lane',
  'yAxisAlign',
  'visible',
  'valueUnitOfMeasure',
];

// Maximum pixel value for small toolbar
export const SMALL_TOOLBAR_MAX_WIDTH = 815; // for CALENDER and CHAIN views
export const SMALL_CAPSULE_TIME_TOOLBAR_MAX_WIDTH = 875; // for CAPSULE and COMPARE views
