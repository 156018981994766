import _ from 'lodash';
import { sqTreesApi } from '@/sdk';
import { isAsset } from '@/utilities/utilities';

/**
 * Determines if the item is in an asset tree and has at least one direct child that is an asset.
 *
 * @param item - The item to check
 */
export async function validateItemHasAtLeastOneAssetChild(item: Record<'id', string>): Promise<boolean> {
  const { data } = await sqTreesApi.getTree({ id: item.id });
  return _.some(data.children, (child) => isAsset(child));
}
