import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Select } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { CapsulePropertyOutputV1, PropertyOutputV1, ScalarPropertyV1, sqItemsApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { fetchConditionProperties } from '@/utilities/investigateHelper.utilities';
import { COLOR_COLUMN_NAME, PRIORITY_COLUMN_NAME } from '@/tableBuilder/tableBuilder.constants';
import { ADVANCED_CAPSULE_PROPERTIES, ADVANCED_PROPERTIES, PropertyColumn } from '@/trendData/trendData.constants';

export enum SuggestedPropertiesMode {
  Series = 'series',
  Condition = 'condition',
  Capsules = 'capsules',
}

export interface ColumnIF {
  propertyName: string;
  style?: string;
  type?: string;
}

export interface CustomPropertySelectorProps {
  itemIds: string[];
  dropdownPlaceholder: string;
  addPropertyColumn: (column: ColumnIF, isCapsuleProperty?: boolean) => void;
  excludedProperties?: string[];
  extraProperties?: PropertyColumn[];
  suggestedPropertiesMode?: SuggestedPropertiesMode;
  closeMenuOnSelect?: boolean;
}

// Properties that are on the underlying backing condition of metrics and thus aren't useful to the user
export const METRIC_CONDITION_PROPERTIES = [COLOR_COLUMN_NAME, PRIORITY_COLUMN_NAME];

// Properties on a condition that are not included in the list of properties returned by the API
const EXTRA_CONDITION_PROPERTIES = [SeeqNames.Properties.Id];

/**
 * Dropdown with selectable, searchable list of item properties
 */
export const CustomPropertySelector: React.FunctionComponent<CustomPropertySelectorProps> = ({
  itemIds,
  addPropertyColumn,
  dropdownPlaceholder,
  excludedProperties = [],
  extraProperties = [],
  suggestedPropertiesMode = SuggestedPropertiesMode.Series,
  closeMenuOnSelect = true,
}) => {
  const { t } = useTranslation();

  const [capsuleProperties, setCapsuleProperties] = useState<string[]>([]);
  type PropertyOption = { value: string; label: string; style: string | undefined };
  const [propertyOptions, setPropertyOptions] = useState<PropertyOption[]>([]);

  useEffect(() => {
    fetchProperties();
  }, []);

  /**
   * Fetches a list of possible properties from the specified items, formats them to be options for the dropdown,
   * and appends an extra properties. If a request fails, then the request will fail silently
   */
  function fetchProperties() {
    return Promise.resolve()
      .then(() =>
        suggestedPropertiesMode === SuggestedPropertiesMode.Series
          ? fetchItemProperties()
          : fetchCapsuleAndConditionProperties(),
      )
      .then((properties) => {
        _.chain(properties)
          .reject((property) =>
            _.chain(excludedProperties)
              .concat(suggestedPropertiesMode === SuggestedPropertiesMode.Capsules ? [] : ADVANCED_PROPERTIES)
              .concat(suggestedPropertiesMode === SuggestedPropertiesMode.Series ? [] : ADVANCED_CAPSULE_PROPERTIES)
              .without(..._.map(extraProperties, valueForPropertyOption))
              .includes(property.name)
              .value(),
          )
          .map(
            (property): PropertyOption => ({
              value: property.name,
              label: property.name,
              style: property.unitOfMeasure,
            }),
          )
          .concat(
            _.map(
              extraProperties,
              (property): PropertyOption => ({
                value: valueForPropertyOption(property),
                label: labelForExtraProperty(property),
                style: undefined,
              }),
            ),
          )
          .uniqBy('value')
          .sortBy('label')
          .thru((options) => setPropertyOptions(options))
          .value();
      });
  }

  /**
   * Fetch properties for all items.
   */
  function fetchItemProperties(): Promise<CapsulePropertyOutputV1[]> {
    return _.chain(itemIds)
      .map((id) => sqItemsApi.getItemAndAllProperties({ id }))
      .thru((results) => Promise.all(results))
      .value()
      .then((responses) => _.flatMap(responses, (r) => propertiesToCapsuleProperties(r.data.properties)));
  }

  /**
   * Fetch condition and capsule properties for all conditions.
   */
  function fetchCapsuleAndConditionProperties(): Promise<CapsulePropertyOutputV1[]> {
    return _.chain(itemIds)
      .map((id) => fetchConditionProperties(id))
      .thru((results) => Promise.all(results))
      .value()
      .then((responses) => {
        const capsuleProperties = _.flatMap(responses, (r) => r.capsuleProperties);
        setCapsuleProperties(
          _.chain(capsuleProperties)
            .map('name')
            .concat(_.map(extraProperties, valueForPropertyOption))
            .uniq()
            .value() as string[],
        );
        if (suggestedPropertiesMode === SuggestedPropertiesMode.Condition) {
          const conditionProperties: CapsulePropertyOutputV1[] = _.flatMap(responses, ({ conditionProperties }) =>
            propertiesToCapsuleProperties(conditionProperties),
          );
          return conditionProperties
            .concat(_.map(EXTRA_CONDITION_PROPERTIES, (name) => ({ name })))
            .concat(capsuleProperties);
        } else {
          return capsuleProperties;
        }
      });
  }

  function valueForPropertyOption(property: PropertyColumn): string {
    return _.isString(property.propertyName) ? property.propertyName : property.key;
  }

  function labelForExtraProperty(property: PropertyColumn): string {
    if (_.isString(property.propertyName)) {
      return property.propertyName;
    } else if (_.isString(property.title)) {
      return t(property.title);
    } else {
      return property.key;
    }
  }

  function propertiesToCapsuleProperties(
    properties: (ScalarPropertyV1 | PropertyOutputV1)[],
  ): CapsulePropertyOutputV1[] {
    return _.map(properties, (property) => ({
      name: property.name,
      unitOfMeasure: property.unitOfMeasure,
    }));
  }

  const addProperty = (column: PropertyOption) => {
    addPropertyColumn({ propertyName: column.value, style: column.style }, _.includes(capsuleProperties, column.label));
  };

  return (
    <div data-testid="customPropertySelector">
      <Select
        // Shows the placeholder value and clears the value after adding the property column
        value={null}
        isSearchable={true}
        extraClassNames="flexFillOverflow"
        placeholder={t(dropdownPlaceholder)}
        options={propertyOptions}
        inputId="customPropertyInput"
        onChange={addProperty}
        creatable={true}
        closeMenuOnSelect={closeMenuOnSelect}
      />
    </div>
  );
};
