import _ from 'lodash';
import { formatDuration, formatTime } from '@/datetime/dateTime.utilities';
import { STRING_UOM } from '@/main/app.constants';
import tinycolor from 'tinycolor2';
import { isStringSeries, isTimestampVisible } from '@/utilities/utilities';
import {
  DETAILS_PANE_ITEM_TYPES,
  ITEM_TYPES,
  SERIES_PANEL_EXTRA_TREND_COLUMNS,
  XY_AXIS_X,
  XY_AXIS_Y,
} from '@/trendData/trendData.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { sqScatterPlotStore, sqTrendStore, sqWorksheetStore } from '@/core/core.stores';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { DEFAULT_ITEM_COLOR } from '@/trend/trendViewer/trendViewer.constants';

interface DecorateOptions {
  /** True if single-select is enabled */
  singleSelect: boolean;
  /** True if plus and minus icons should be used */
  pickingMode: boolean;
}

interface DecorateItemParams {
  /** The item being decorated */
  item: any;
  /** Options for customizing behavior */
  options?: DecorateOptions;
}

/**
 * Decorates a capsule with view-level properties.
 *
 * @returns {Object} The decorated item.
 */
function decorateCapsule({ item, options }: DecorateItemParams) {
  const formattedStartTime = item.startTime ? formatTime(item.startTime, sqWorksheetStore.timezone) : undefined;
  const formattedEndTime = item.endTime ? formatTime(item.endTime, sqWorksheetStore.timezone) : undefined;
  const formattedDuration = _.isNumber(item.duration) ? formatDuration(item.duration, { trim: false }) : undefined;
  const isUncertain = item.isUncertain;
  const decoratedItem = _.assign({}, decorateItem({ item, options }), {
    formattedStartTime,
    formattedEndTime,
    formattedDuration,
    isUncertain,
  });

  return _.assign(decoratedItem, {
    selectTooltip: selectTooltip(decoratedItem, options),
    selectIcon: selectIcon(decoratedItem, options),
  });
}

interface SortAndDecorateItemsParams {
  /** Container holding sorting parameters */
  sortParam: {
    /** property to sort on */
    sortBy: string;
    /** if truthy the items should be sorted in ascending order */
    sortAsc: boolean;
  };
  /** collection of items to be decorated and sorted */
  items: any[];
  /** Options for the various decorators */
  options?: any;
  sqTrendStore: any;
}

/**
 * Sorts the items with a sort column and order. Then decorates the items.
 */
export function sortAndDecorateItems({ sortParam: { sortBy, sortAsc }, items, options }: SortAndDecorateItemsParams) {
  return _.chain(items)
    .thru((items) => decorate({ items, options }))
    .map((item) => {
      // This logic allows for case-insensitive sorting. It needs to be done outside of the _.orderBy call below
      // or else numbers will not sort properly
      let value = '';
      if (sortBy === SERIES_PANEL_EXTRA_TREND_COLUMNS[0] && item?.assets) {
        value = item.assets[0]?.name;
      } else if (sortBy === SERIES_PANEL_EXTRA_TREND_COLUMNS[3] && item?.assets) {
        value = item.assets[0]?.formattedName;
      } else {
        value = _.get(item, sortBy);
      }
      return {
        ...item,
        sortValue: _.isString(value) ? _.toLower(value) : value,
      };
    })
    .orderBy(['sortValue'], [sortAsc ? 'asc' : 'desc'])
    .value();
}

/**
 * Decorates an item, or collection of items, with additional view-level properties. Item must be one of those
 * specified by DETAILS_PANE_ITEM_TYPES.
 *
 * @returns {Object} The decorated item or collection of items.
 */
export function decorate({
  items,
  options = { singleSelect: false, pickingMode: false },
}: Omit<DecorateItemParams, 'item'> & {
  /** The item or collection of items being decorated */
  items: any;
}) {
  return _.isArray(items) ? _.map(items, _decorate) : _decorate(items);

  function _decorate(item: any) {
    if (_.isNil(item)) {
      // Data hasn't loaded yet, don't do anything.
      return item;
    } else if (_.includes(DETAILS_PANE_ITEM_TYPES, item.itemType)) {
      return decorateItem({ item, options });
    } else if (item.itemType === ITEM_TYPES.CAPSULE) {
      return decorateCapsule({
        item,
        options,
      });
    } else {
      throw new TypeError(`${item.itemType} is unsupported`);
    }
  }
}

/**
 * Get ScatterPlot Min and Max Axis for the selected signals
 * @param item
 *
 * @returns Object - min and max axis
 */
function getScatterplotAxis(item: any) {
  if (item.itemType !== ITEM_TYPES.SERIES || !isScatterPlotSignalSelected(item)) {
    return {
      yAxisMin: null,
      yAxisMax: null,
    };
  }

  if (item.id === sqScatterPlotStore.xSignal?.id) {
    const range = sqScatterPlotStore.getXAxisRange();
    return {
      yAxisMin: range.start,
      yAxisMax: range.end,
    };
  }

  if (_.includes(_.map(sqScatterPlotStore.ySignals, 'id'), item.id)) {
    const range = sqScatterPlotStore.getYAxisRange(item.id);
    return {
      yAxisMin: range.start,
      yAxisMax: range.end,
    };
  }
}

/**
 * Check if the item is selected in the toolbar and can be customized
 * @param item
 */
function isScatterPlotSignalSelected(item: any) {
  return _.chain(sqScatterPlotStore.ySignals)
    .map('id')
    .concat(sqScatterPlotStore.xSignal?.id)
    .includes(item.id)
    .value();
}

/**
 * Axis Customization for each item
 *
 * @param {Object} item - The item being decorated
 */
function getAxisCustomizationForItem(item: any) {
  let axisCustomization;
  if (sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT) {
    const axis = item.id === sqScatterPlotStore.xSignal?.id ? XY_AXIS_X : XY_AXIS_Y;
    axisCustomization = {
      ...getScatterplotAxis(item),
      isCustomizationHidden:
        isStringSeries(item) ||
        (item.itemType === ITEM_TYPES.SERIES && !isScatterPlotSignalSelected(item)) ||
        !sqScatterPlotStore.xSignal?.id ||
        _.isEmpty(sqScatterPlotStore.ySignals),
      axisAlign: !isScatterPlotSignalSelected(item) ? null : axis,
      axisAutoScale: !sqScatterPlotStore.isViewRegionSet(),
    };
  }

  return axisCustomization;
}

/**
 * Base decorator for all items.
 *
 * @returns {Object} The decorated item.
 */
function decorateItem({ item, options }: DecorateItemParams) {
  const classes = ['cursorPointer'];
  if (item.selected) {
    classes.push('selectedTableRow');
  }
  if (item.isUncertain) {
    classes.push('uncertain');
  }
  const displayUnitOfMeasure = _.cond([
    // In CRAB-8217, users found the 'string' unit of measure as confusing, so we hide it.
    [(valueUom) => valueUom === STRING_UOM, _.constant({ isRecognized: true, value: '' })],
    [
      _.isEmpty,
      _.constant({
        isRecognized: false,
        value: item.sourceValueUnitOfMeasure,
      }),
    ],
    [_.stubTrue, (value) => ({ isRecognized: true, value })],
  ])(item.valueUnitOfMeasure);

  const color = tinycolor(item.color).isValid() ? item.color : DEFAULT_ITEM_COLOR;

  return _.assign({}, item, {
    displayUnitOfMeasure,
    rowClasses: classes.join(' '),
    selectTooltip: selectTooltip(item, options),
    selectIcon: selectIcon(item, options),
    style: { color },
    color,
    translateKey:
      item.itemType !== ITEM_TYPES.METRIC
        ? item.translateKey
        : _.cond([
            [
              (metric: { definition?: { processType: ProcessTypeEnum } }) =>
                metric.definition?.processType === ProcessTypeEnum.Simple,
              () => 'METRIC_SIMPLE',
            ],
            [
              (metric: { definition?: { processType: ProcessTypeEnum } }) =>
                metric.definition?.processType === ProcessTypeEnum.Condition,
              () => 'METRIC_CONDITION',
            ],
            [
              (metric: { definition?: { processType: ProcessTypeEnum } }) =>
                metric.definition?.processType === ProcessTypeEnum.Continuous,
              () => 'METRIC_CONTINUOUS',
            ],
            [_.stubTrue, () => 'METRIC'],
          ])(item),
    onChange: _.noop,
    ...getAxisCustomizationForItem(item),
  });
}

/**
 * Returns the tooltip for the "select" checkbox for an item.
 *
 * @param {Object} item - The item being decorated
 * @param {Object} options - Options for customizing behavior
 * @param {Boolean} [options.pickingMode=false] - True if plus and minus icons should be used
 *
 * @returns {string} translation key for the tooltip.
 */
function selectTooltip(item: any, options?: DecorateOptions): string {
  let mode = ['DESELECT', 'SELECT'];
  if (item.autoDisabled) {
    mode = ['DISABLED', 'DISABLED'];
  } else if (
    item.itemType === ITEM_TYPES.CAPSULE &&
    !isTimestampVisible(item.startTime) &&
    sqTrendStore.isTrendViewCapsuleTime()
  ) {
    const tooltip = 'CAPSULES_PANEL.ZOOM_OUT_TO_VIEW_MORE_CAPSULE_VIEW';
    mode = [tooltip, tooltip];
  } else if (item.notFullyVisible) {
    const tooltip = 'CAPSULES_PANEL.NOT_SELECTABLE_NOT_VISIBLE_TOOLTIP';
    mode = [tooltip, tooltip];
  } else if (options?.pickingMode) {
    mode = ['REMOVE', 'ADD'];
  }
  const [selected, deselected] = mode;
  return item.selected ? selected : deselected;
}

/**
 * Returns the classes that are used for creating the "select" checkbox for an item.
 *
 * @param {Object} item - The item being decorated
 * @param {DecorateOptions} [options] - Options for customizing behavior
 *
 * @returns {string} The classes that indicate a checked or unchecked select box.
 */
function selectIcon(item: any, options?: DecorateOptions) {
  let mode = ['fa-check-square', 'fa-square-o'];
  if (item.autoDisabled) {
    mode = ['fa-ban sq-fairly-dark-gray', 'fa-ban sq-fairly-dark-gray'];
  } else if (
    item.itemType === ITEM_TYPES.CAPSULE &&
    !isTimestampVisible(item.startTime) &&
    sqTrendStore.isTrendViewCapsuleTime()
  ) {
    const classes = 'fa-info-circle sq-darkish-gray sq-text-warning';
    mode = [classes, classes];
  } else if (item.notFullyVisible) {
    mode = ['fa-ban sq-darkish-gray', 'fa-ban sq-darkish-gray'];
  } else if (options?.singleSelect) {
    mode = ['fa-check-circle', 'fa-circle-thin'];
  } else if (options?.pickingMode) {
    mode = ['fa-minus-circle', 'fa-plus-circle'];
  }
  const [selected, deselected] = mode;

  return `fa-fw showHideTrend fa ${item.selected ? selected : deselected}`;
}
